:root {
  --cor-principal: #647BBB;
  --cor-principal-50: #647BBB50;
  --cor-principal-25: #647BBB25;
  --cor-principal-20: calc(var(--cor-principal) + 20);
  --cor-principal-10: #647BBB10;
  --largura-menu: 18rem;
  --font-family: "Segoe UI Emoji", "Kumbh Sans Light", Arial, Helvetica, sans-serif !important;
  .p-component {
    font-family: var(--font-family);
  }
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(../src/assets/fonts/password.ttf);
}

html {
  font-size: 12.5px;
  overflow: hidden;
}

body {
  font-family: var(--font-family-sans-serif);
}

.p-inputtext {
  font-family: var(--font-family);
}

h4 {
  color: #647BBB !important;
}

/*

  ZOOM DE 90%

*/
@media (max-width: 1600px) {
  app-root {
    zoom: 90%;
  }
  .centered-content {
    p {
      font-size: 20px !important;
    }
  }
  .button-notification {
    i {
      top: -14px !important;
    }
  }
  .p-datepicker .p-datepicker-header {
    padding: 2.5rem !important;
  }
  .selecao-mes {
    padding-top: 16px !important;
  }
}

/*

  ZOOM DE 80%

*/
@media (max-width: 1400px) {
  app-root {
    zoom: 80%;
  }
  html {
    font-size: 12px;
  }
  .logo-header {
    height: 84px !important;
    width: 235px !important;
  }
  .centered-content {
    p {
      font-size: 18px !important;
    }
  }
}

/*

  ZOOM DE 70%

*/
@media (max-width: 1200px) {
  app-root {
    zoom: 70%;
  }
  html {
    font-size: 11px;
  }
  .logo-header {
    height: 76px !important;
    width: 215px !important;
  }
  .centered-content {
    p {
      font-size: 16px !important;
    }
  }
}

.calendario-periodo {
  .botao {
    span {
      margin-top: 2px;
      margin-right: 2px;
    }
  }
}

.campo-empresas-top-bar {
  .p-inputtext {
    width: 450px;
  }
}

.selecao-mes {
  padding-top: 14.9px;
  height: 52px;
}

/* Change the white to any color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::placeholder {
  color: lightgrey !important;
}

.titulo-btn-acoes {
  border-bottom: 1px solid rgb(228, 228, 228);
  padding-bottom: 14px;
}

body {
  margin: 0;
  background-color: #f8f7f7;
}

// Container padrão de todas as páginas, customizada no SCSS de cada pagina se necessário
.container {
  margin: 0;
  margin-right: 20px;
  position: fixed;
  height: -webkit-fill-available;
  width: calc(100% - (var(--largura-menu)));
  overflow-y: auto;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  .container-width {
    padding-top: 2.5rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    background-color: #f8f7f7;
    width: 1300px;
  }
}

.conteudo::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.conteudo::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 10px;
}

.conteudo::-webkit-scrollbar-thumb {
  background-color: #d4aa70;
  border-radius: 10px;
}



.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  label {
    margin-bottom: 5px;
  }
}

.p-error {
  text-align: end;
  font-size: 9px;
}

.input-label {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}

.input-date {
  .p-calendar .p-inputtext {
    width: 92px;
  }
}

.margin-r-1 {
  margin-right: 7px;
}

.margin-r-2 {
  margin-right: 14px;
}

.margin-r-3 {
  margin-right: 24px;
}

// Altura das linhas da grid
.p-datatable .p-datatable-tbody > tr {
  height: 3rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 1rem 0rem 1rem;
  font-family: "Lucida Console", monospace;
  vertical-align: middle;
}

.p-button {
  padding: 0.55rem 0.75rem !important;
}

.p-button-icon-left {
  padding-right: 7px;
  margin-left: -8px;
}

.botao-custom {
  padding-left: 2.2rem !important;
  padding-right: 2.2rem !important;
  height: 29px;
}

.p-dropdown {
  height: 28px;
  align-items: center !important;
}

.botao-custom-sm {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.botao-custom > .pi {
  font-size: 1.1rem;
}

.p-button-sm {
  //font-size: 0.98rem;
  //height: 35px;
  .p-button-icon-left {
    margin-left: 0px;
    margin-right: 0px !important;
    margin-bottom: 0px;
  }
  .p-button-label {
    margin-top: -1px;
    margin-bottom: 2px;
  }
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.9rem !important;
  font-weight: bold;
}

.pi-refresh {
  font-size: 18px !important;
}

/* PrimeNg Upload */
.p-fileupload-row {
  justify-content: space-between;
}
.p-fileupload {
  .p-fileupload-buttonbar {
    padding: 1rem !important;
    .p-button-label {
      font-weight: 500;
    }
    .p-button {
      padding: 0.5rem 1.25rem !important;
    }
    .p-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 4px;
    }
  }
  .p-fileupload-content {
    background: #ffffff;
    padding: 0rem 1rem;
    border: 2px solid #ebebeb;
    color: #6c6c6c;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    min-height: 240px;
    max-height: 240px;
  }
}
.p-fileupload-row > div {
  width: auto !important;
  .p-button.p-button-icon-only {
    width: 2rem;
    height: 2rem;
  }
}
.p-fileupload .p-fileupload-row > div {
    padding: 0rem 1rem !important;
    margin-left: -20px;
    margin-top: -16px;
    margin-bottom: -18px;
}

.p-dialog {
  z-index: initial;
  box-shadow: 0px 1px 14px 8px rgb(0 0 0 / 20%) !important;
  top: -10%;
  position: initial !important;
  .p-dialog-header {
    font-size: 16px;
    margin-bottom: -1px;
  }
}

// Remove as bordas do botão quando ele esta em foco
.p-button:focus, .p-button:enabled:focus {
  box-shadow:none;
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
}

.p-radiobutton-icon {
  background-color: #5274D9 !important;
}

.p-progress-spinner {
  width: 60px !important;
  height: 60px !important;
}

.field-checkbox {
  display: flex;
  vertical-align: middle;
}

.p-button.p-button-rounded {
  padding-left: 10px;
  padding-right: 10px;
  i {
    font-size: 16px;
  }
}

.p-menu .p-submenu-header {
  padding: 0rem 1.25rem !important;
}

.ativa {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 15px;
  height: 15px;
  background-color: rgb(0, 150, 0);
  opacity: 0.8;
  color: white;
  border-radius: 10px;
}

.inativa {
  background-color: rgb(255, 80, 0);
}

.p-toast .p-toast-detail {
  white-space: pre-wrap;
}

.p-highlight-contextmenu {
  background-color: #EFF6FF !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.40rem 1.25rem !important;
  .p-inputtext {
    padding: 0.35rem 0.75rem !important;
  }
}

.p-multiselect-panel {
  .p-inputtext {
    padding: 0.35rem 0.75rem !important;
  }
}

.calendario-mes {
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 180px;
  background-color: white;
  border: solid 1px white;
  border-radius: 7px;
  .input-mes-ano {
    input {
      text-align: center;
      cursor: pointer;
      width: 198px
    }
  }
  button {
    z-index: 1;
    height: 25px;
    margin-top: 1.5px;
    padding: 2px 5px 0px 3px;
    border-radius: 4px;
    margin-left: -32px;
    margin-right: -32px;
    span {
      padding-left: 5px;
      padding-right: 5px;
      color: #6c6c6c;
    }
  }
  button.p-datepicker-next.p-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  button.p-datepicker-prev.p-link{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button:hover {
    background-color: lightgray;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev {
    margin-left: -6px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    margin-right: -6px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker-next {
    width: 30px;
    margin-bottom: 7px;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
    margin-bottom: 0px;
    padding: 0px 10px;
  }
}

.p-calendar {
  height: 28px;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 3px 3px 0px 3px !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 2.15rem;
  margin-right: 0.35rem;
  height: 30px;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.45rem 0.55rem !important;
}

label {
  color: var(--cor-principal);
  font-weight: 500;
}

.p-fieldset-legend>a, .p-fieldset-legend>span {
  font-size: 14px;
  background-color: var(--surface-a);
  justify-content: flex-start !important;
  border: 2px solid var(--surface-border);
  border-bottom: none;
  border-radius: 0.4rem 0.4rem 0rem 0rem;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2.55rem;
  margin-bottom: -1rem;
  padding: 0.7rem;
  margin-left: -5px;
}

.p-fieldset .p-fieldset-legend {
  margin-bottom: -0.5rem;
  padding: 0;
  border: none !important;
}

.p-datepicker table td {
  padding: 0.1rem !important;
}

.p-datepicker table td > span {
  width: 1.7rem;
  height: 1.7rem;
}

.p-datepicker table {
  z-index: 00099999;
  font-size: 10pt;
  margin: -0.143rem 0;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.font-monospaced {
  font-family: 'DejaVu Sans Mono', 'Andale Mono', 'Lucida Console', monospace
}

tbody, td, tfoot, th, thead, tr {
  z-index: 0 !important;
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  background-color: var(--cor-principal-20);
}

.p-dropdown-filter-icon {
  margin-top: -0.5rem !important;
}

.p-dropdown-filter-container {
  height: 30px !important;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 1rem 0.5rem 1rem 0.5rem !important;
}

.button-grouped {
  border-color: #e1e1e1;
  margin-left: -2px;
  .pi {
    color: gray;
  }
}

.centered-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 300px;
  top: 100px;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 300px;
  }

  p {
    font-size: 22px;
    color: #647BBB;
    margin-top: -70px;
  }
}

.p-card .p-card-title {
  font-family: var(--font-family);
  font-size: 14px;
  color: rgb(145, 145, 145);
  font-weight: 600;
  padding-top: 12px;
}

.p-overlaypanel-content .p-button {
  padding-left: 16px !important;
}

.nfse-ativa {
  font-size: 11px;
  font-weight: 700;
  padding: 0.4rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  border-radius: .4rem;
  background: var(--green-100);
  color: var(--green-700);
}

.nfse-cancelada {
  font-size: 10px;
  font-weight: 700;
  padding: 0.5rem;
  border-radius: .4rem;
  background: var(--red-100);
  color: var(--red-700);
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 10px;
}

.p-inputswitch .p-inputswitch-slider::before {
  border-radius: 10px;
  left: 0.18rem;
}
