/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-heading-color: inherit;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.m-1 {
  margin: 0.25rem;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.mt-3,
.my-3 {
  margin-top: 1rem;
}

.mr-3,
.mx-3 {
  margin-right: 1rem;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem;
}

.ml-3,
.mx-3 {
  margin-left: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.mt-5,
.my-5 {
  margin-top: 3rem;
}

.mr-5,
.mx-5 {
  margin-right: 3rem;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem;
}

.ml-5,
.mx-5 {
  margin-left: 3rem;
}

.p-0 {
  padding: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

.p-1 {
  padding: 0.25rem;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.pt-3,
.py-3 {
  padding-top: 1rem;
}

.pr-3,
.px-3 {
  padding-right: 1rem;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem;
}

.pl-3,
.px-3 {
  padding-left: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.pt-5,
.py-5 {
  padding-top: 3rem;
}

.pr-5,
.px-5 {
  padding-right: 3rem;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem;
}

.pl-5,
.px-5 {
  padding-left: 3rem;
}

.m-n1 {
  margin: -0.25rem;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem;
}

.m-n2 {
  margin: -0.5rem;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem;
}

.m-n3 {
  margin: -1rem;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem;
}

.m-n4 {
  margin: -1.5rem;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem;
}

.m-n5 {
  margin: -3rem;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem;
}

.m-auto {
  margin: auto;
}

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0;
  }
  .m-sm-1 {
    margin: 0.25rem;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem;
  }
  .m-sm-2 {
    margin: 0.5rem;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem;
  }
  .m-sm-3 {
    margin: 1rem;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem;
  }
  .m-sm-4 {
    margin: 1.5rem;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem;
  }
  .m-sm-5 {
    margin: 3rem;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0;
  }
  .p-sm-1 {
    padding: 0.25rem;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem;
  }
  .p-sm-2 {
    padding: 0.5rem;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem;
  }
  .p-sm-3 {
    padding: 1rem;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem;
  }
  .p-sm-4 {
    padding: 1.5rem;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem;
  }
  .p-sm-5 {
    padding: 3rem;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem;
  }
  .m-sm-n1 {
    margin: -0.25rem;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem;
  }
  .m-sm-n2 {
    margin: -0.5rem;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem;
  }
  .m-sm-n3 {
    margin: -1rem;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem;
  }
  .m-sm-n4 {
    margin: -1.5rem;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem;
  }
  .m-sm-n5 {
    margin: -3rem;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0;
  }
  .m-md-1 {
    margin: 0.25rem;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem;
  }
  .m-md-2 {
    margin: 0.5rem;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem;
  }
  .m-md-3 {
    margin: 1rem;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem;
  }
  .m-md-4 {
    margin: 1.5rem;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem;
  }
  .m-md-5 {
    margin: 3rem;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem;
  }
  .p-md-0 {
    padding: 0;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0;
  }
  .p-md-1 {
    padding: 0.25rem;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem;
  }
  .p-md-2 {
    padding: 0.5rem;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem;
  }
  .p-md-3 {
    padding: 1rem;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem;
  }
  .p-md-4 {
    padding: 1.5rem;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem;
  }
  .p-md-5 {
    padding: 3rem;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem;
  }
  .m-md-n1 {
    margin: -0.25rem;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem;
  }
  .m-md-n2 {
    margin: -0.5rem;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem;
  }
  .m-md-n3 {
    margin: -1rem;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem;
  }
  .m-md-n4 {
    margin: -1.5rem;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem;
  }
  .m-md-n5 {
    margin: -3rem;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0;
  }
  .m-lg-1 {
    margin: 0.25rem;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem;
  }
  .m-lg-2 {
    margin: 0.5rem;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem;
  }
  .m-lg-3 {
    margin: 1rem;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem;
  }
  .m-lg-4 {
    margin: 1.5rem;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem;
  }
  .m-lg-5 {
    margin: 3rem;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem;
  }
  .p-lg-0 {
    padding: 0;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0;
  }
  .p-lg-1 {
    padding: 0.25rem;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem;
  }
  .p-lg-2 {
    padding: 0.5rem;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem;
  }
  .p-lg-3 {
    padding: 1rem;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem;
  }
  .p-lg-4 {
    padding: 1.5rem;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem;
  }
  .p-lg-5 {
    padding: 3rem;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem;
  }
  .m-lg-n1 {
    margin: -0.25rem;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem;
  }
  .m-lg-n2 {
    margin: -0.5rem;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem;
  }
  .m-lg-n3 {
    margin: -1rem;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem;
  }
  .m-lg-n4 {
    margin: -1.5rem;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem;
  }
  .m-lg-n5 {
    margin: -3rem;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0;
  }
  .m-xl-1 {
    margin: 0.25rem;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem;
  }
  .m-xl-2 {
    margin: 0.5rem;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem;
  }
  .m-xl-3 {
    margin: 1rem;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem;
  }
  .m-xl-4 {
    margin: 1.5rem;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem;
  }
  .m-xl-5 {
    margin: 3rem;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem;
  }
  .p-xl-0 {
    padding: 0;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0;
  }
  .p-xl-1 {
    padding: 0.25rem;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem;
  }
  .p-xl-2 {
    padding: 0.5rem;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem;
  }
  .p-xl-3 {
    padding: 1rem;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem;
  }
  .p-xl-4 {
    padding: 1.5rem;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem;
  }
  .p-xl-5 {
    padding: 3rem;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem;
  }
  .m-xl-n1 {
    margin: -0.25rem;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem;
  }
  .m-xl-n2 {
    margin: -0.5rem;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem;
  }
  .m-xl-n3 {
    margin: -1rem;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem;
  }
  .m-xl-n4 {
    margin: -1.5rem;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem;
  }
  .m-xl-n5 {
    margin: -3rem;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto;
  }
}


.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.d-flex {
  display: -ms-flexbox;
  display: flex;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-fill {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-grow-0 {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.align-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-sm-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-sm-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-sm-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-sm-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-md-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-md-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-md-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-md-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-md-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-md-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-md-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-md-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-md-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-md-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-lg-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-lg-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-lg-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-lg-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-xl-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-xl-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-xl-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-xl-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left;
  }
  .float-sm-right {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left;
  }
  .float-md-right {
    float: right;
  }
  .float-md-none {
    float: none;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left;
  }
  .float-lg-right {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left;
  }
  .float-xl-right {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
}

.user-select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}

.user-select-auto {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.min-vw-100 {
  min-width: 100vw;
}

.min-vh-100 {
  min-height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.vh-100 {
  height: 100vh;
}


.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}


.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-break {
  word-break: break-word;
  word-wrap: break-word;
}

.text-reset {
  color: inherit;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.d-none {
  display: none !important;
}
